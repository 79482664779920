import { useParams } from 'react-router-dom-latest';
import { useMatchNavigation } from 'hooks';
import {
  BROKER_NESTED_ROUTES,
  generatePortalSubmissionPath,
  generateSubmissionPath,
  MarketingUpdateRoutes,
  QuoteComparisonInsightsRoutes,
  QuoteFormRoutes,
  WORKSPACE_TABS_ROUTES,
} from 'broker/broker-routes';
import { StepsRoutes } from 'broker/dialogs/SubmissionAutonomousCreation/types';

export function useWorkspaceUrls() {
  const activeTab = useMatchNavigation(WORKSPACE_TABS_ROUTES);

  const { id } = useParams();

  return {
    editSubmissionUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.EDIT_SUBMISSION}`,
    viewPortalQuoteUrl: `${generatePortalSubmissionPath(id)}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}`,

    autonomousSettingsUrl: `${generateSubmissionPath(id, activeTab)}/${
      BROKER_NESTED_ROUTES.WORKSPACE.SUBMISSION_AUTONOMOUS_CREATION
    }/${StepsRoutes.Advanced}`,
    singleMarketUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.SINGLE_MARKET}`,
    conceptualTowerUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.CONCEPTUAL_TOWER}`,
    proposalFlowUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.PROPOSAL}`,

    marketingReportUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT}`,
    emailEditorUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR}`,
    compareQuotesUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.COMPARE_QUOTES}`,
    EmailReplyUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_REPLY}`,
    quoteFormUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/${
      QuoteFormRoutes.Details
    }`,
    flowQuoteUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/${
      QuoteFormRoutes.FlowQuote
    }`,
    createQuoteFormUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/${
      QuoteFormRoutes.Upload
    }`,
    selectProductsUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.SELECT_PRODUCTS}`,
    acknowledgmentEmailUrl: `${generateSubmissionPath(id, activeTab)}/${
      BROKER_NESTED_ROUTES.WORKSPACE.ACKNOWLEDGMENT_EMAIL
    }`,
    createBinderUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.BINDER}/${
      QuoteFormRoutes.Upload
    }`,
    binderUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.BINDER}/${
      QuoteFormRoutes.Details
    }`,
    policyUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.POLICY}/${
      QuoteFormRoutes.Details
    }`,
    createPolicyUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.POLICY}/${
      QuoteFormRoutes.Upload
    }`,

    quoteStatusUrl: `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_STATUS}`,
    submissionEmailUrl: (submissionEmailId: string) =>
      `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_REPLY}/${submissionEmailId}`,
    quoteComparisonInsightsUrl: `${generateSubmissionPath(id, activeTab)}/${
      BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_COMPARISON_INSIGHTS
    }/${QuoteComparisonInsightsRoutes.Compose}`,
    compareQuotesSideBySideUrl: `${generateSubmissionPath(id, activeTab)}/${
      BROKER_NESTED_ROUTES.WORKSPACE.COMPARE_QUOTES_SIDE_BY_SIDE
    }`,
    marketingUpdateUrl: (route?: MarketingUpdateRoutes) =>
      `${generateSubmissionPath(id, activeTab)}/${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_UPDATE}/${
        route ?? MarketingUpdateRoutes.Compose
      }`,
    marketingUpdateEmailUrl: `${generateSubmissionPath(id, activeTab)}/${
      BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_UPDATE_EMAIL
    }`,
    marketingWorkspaceUrl: `${generateSubmissionPath(id)}/${WORKSPACE_TABS_ROUTES.MARKETING}`,
  };
}
