import { compact, isEmpty, lowerCase } from 'lodash';
import { DeleteOutline, EmailOutlined, Launch, SettingsEthernetOutlined } from '@icons';
import { QuoteFileIdType, SubmissionMarketRequestStatus, SubmissionMethod } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';
import { ExtendedMarketRequest } from 'types';
import { MenuGroup, MenuItemProps } from 'components/menu';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { areHeraldProductsEligibleToCreateSubmission } from 'broker/pages/SubmissionWorkspacePage/utils';
import { useSubmissionMarketRequestActions } from './useSubmissionMarketRequestActions';

export default function useDropdownMenuItems(
  marketRequest: ExtendedMarketRequest,
  setIsSubmittingMarketRequest?: (isSubmitting: boolean) => void,
): MenuGroup[] {
  const { submission } = useSubmissionsWorkspace();
  const {
    submitToMarketByEmail,
    submitToMarketByPortal,
    submitProductViaApi,
    changeToAwaitingQuote,
    openDeclination,
    openInformationRequested,
    attachQuote,
    editQuote,
    viewQuote,
    viewBinder,
    viewPolicy,
    rejectQuote,
    attachStatus,
    removeDeclination,
    removeQuote,
    removeBinder,
    removePolicy,
    deleteSubmissionMarketRequest,
    changeToAwaitingQuoteViaPortal,
    changeToAwaitingBinder,
    removeInformationRequested,
    composeAdditionalInformationToMarketEmail,
    shareQuote,
    shareBinder,
    sharePolicy,
    markQuoteAsShared,
    markBinderAsShared,
    markPolicyAsShared,
    editPolicy,
    editBinder,
  } = useSubmissionMarketRequestActions(marketRequest, setIsSubmittingMarketRequest);

  const menuItems = {
    submitViaApi: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.submitViaAPI,
      onClick: submitProductViaApi,
      startAdornment: SettingsEthernetOutlined,
    },
    submitViaEmail: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.submitViaEmail,
      onClick: submitToMarketByEmail,
      startAdornment: EmailOutlined,
    },
    submitViaPortal: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.submitViaPortal,
      onClick: submitToMarketByPortal,
      startAdornment: Launch,
    },
    openPortal: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.openPortal,
      onClick: submitToMarketByPortal,
    },
    setAwaitingQuoteViaPortal: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.setAwaitingQuoteViaPortal,
      onClick: changeToAwaitingQuoteViaPortal,
    },
    deleteProduct: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.deleteProduct,
      onClick: () => deleteSubmissionMarketRequest(marketRequest.id),
      textColor: theme.palette.error.main,
      confirmationDialog: {
        message: messages.submissionWorkspace.marketRequestItem.dropDownItems.confirmDeleteProduct,
        title: messages.submissionWorkspace.marketRequestItem.dropDownItems.deleteProduct,
      },
      startAdornment: DeleteOutline,
    },
    setAwaitingQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.setAwaitingQuote,
      onClick: changeToAwaitingQuote,
    },
    attachQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.attachQuote,
      onClick: attachQuote,
    },
    addInformationRequest: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.addInformationRequest,
      onClick: openInformationRequested,
    },
    updateInformationRequest: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.updateInformationRequest,
      onClick: openInformationRequested,
    },
    addDeclinationReason: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.addDeclinationReason,
      onClick: openDeclination,
    },
    sendAdditionalInformationToMarket: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.sendAdditionalInformationToMarket,
      onClick: composeAdditionalInformationToMarketEmail,
    },
    viewQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.viewQuote,
      onClick: viewQuote,
    },
    attachBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.attachBinder,
      onClick: attachStatus(QuoteFileIdType.Binder),
    },
    setAwaitingBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.setAwaitingBinder,
      onClick: changeToAwaitingBinder,
    },
    shareQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.shareQuote,
      onClick: shareQuote,
    },
    markQuoteAsShared: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.markQuoteAsShared,
      onClick: markQuoteAsShared,
    },
    editQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.editQuote,
      onClick: editQuote,
    },
    rejectQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.rejectQuote,
      onClick: rejectQuote,
    },
    removeQuote: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeQuote,
      onClick: removeQuote,
      confirmationDialog: {
        title: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeQuote,
        message: messages.submissionWorkspace.marketRequestItem.dropDownItems.confirmRemoveQuote,
      },
    },
    editBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.editBinder,
      onClick: editBinder,
    },

    viewBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.viewBinder,
      onClick: viewBinder,
    },
    shareBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.shareBinder,
      onClick: shareBinder,
    },
    markBinderAsShared: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.markBinderAsShared,
      onClick: markBinderAsShared,
    },
    attachPolicy: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.attachPolicy,
      onClick: attachStatus(QuoteFileIdType.Policy),
    },
    editPolicy: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.editPolicy,
      onClick: editPolicy,
    },
    removeBinder: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeBinder,
      onClick: removeBinder,
      confirmationDialog: {
        title: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeBinder,
        message: messages.submissionWorkspace.marketRequestItem.dropDownItems.confirmRemoveBinder,
      },
    },
    editDeclinationReason: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.editDeclinationReason,
      onClick: openDeclination,
    },
    removeDeclination: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeDeclination,
      onClick: removeDeclination,
      confirmationDialog: {
        title: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeDeclination,
        message: messages.submissionWorkspace.marketRequestItem.dropDownItems.confirmRemoveDeclination,
      },
    },
    viewPolicy: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.viewPolicy,
      onClick: viewPolicy,
    },
    sharePolicy: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.sharePolicy,
      onClick: sharePolicy,
    },
    markPolicyAsShared: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.markPolicyAsShared,
      onClick: markPolicyAsShared,
    },
    removePolicy: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.removePolicy,
      onClick: removePolicy,
      confirmationDialog: {
        title: messages.submissionWorkspace.marketRequestItem.dropDownItems.removePolicy,
        message: messages.submissionWorkspace.marketRequestItem.dropDownItems.confirmRemovePolicy,
      },
    },
    removeInformationRequested: {
      label: messages.submissionWorkspace.marketRequestItem.dropDownItems.removeInformationRequest,
      onClick: removeInformationRequested,
    },
  };

  function getAvailableSubmissionMethods(): MenuItemProps[] {
    const items: MenuItemProps[] = [];
    if (
      submission &&
      marketRequest.insuranceProduct?.externalProductId &&
      marketRequest.submissionMethod !== SubmissionMethod.API // Do not allow to submit via api twice
    ) {
      const validation = areHeraldProductsEligibleToCreateSubmission(submission, [
        marketRequest.insuranceProduct?.externalProductId,
      ]);

      items.push({
        ...menuItems.submitViaApi,
        disabled: !validation.eligible,
        disabledTooltipContent: validation.reason,
      });
    }
    items.push(menuItems.submitViaEmail);

    if (marketRequest.insuranceProduct?.submissionPortal) {
      items.push(menuItems.submitViaPortal);
    }

    return items;
  }

  const createNestedSection = (label: string, items: MenuItemProps[]): MenuItemProps => ({
    label,
    subMenu: [{ key: lowerCase(label), items }],
  });

  const hasFlowQuote = !isEmpty(marketRequest.quote?.capitolaQuoteFileIds);
  const hasBinder = !isEmpty(marketRequest.quote?.binderFileIds);

  const getStatusBasedActions = (status: SubmissionMarketRequestStatus): MenuItemProps[] => {
    const config: Record<SubmissionMarketRequestStatus, (MenuItemProps | undefined)[]> = {
      [SubmissionMarketRequestStatus.ReadyToMarket]: compact([
        ...getAvailableSubmissionMethods(),
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            menuItems.setAwaitingQuote,
            marketRequest.insuranceProduct?.submissionPortal ? menuItems.setAwaitingQuoteViaPortal : undefined,
            !marketRequest.quoteId && menuItems.attachQuote,
            isEmpty(marketRequest.informationRequested)
              ? menuItems.addInformationRequest
              : menuItems.updateInformationRequest,
            menuItems.addDeclinationReason,
            menuItems.deleteProduct,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.AwaitingQuote]: compact([
        !marketRequest.quoteId && menuItems.attachQuote,
        isEmpty(marketRequest.informationRequested)
          ? menuItems.addInformationRequest
          : menuItems.updateInformationRequest,
        menuItems.addDeclinationReason,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            createNestedSection(
              messages.submissionWorkspace.marketRequestItem.dropDownItems.resubmit,
              compact([...getAvailableSubmissionMethods()]),
            ),
            marketRequest.insuranceProduct?.submissionPortal && menuItems.setAwaitingQuoteViaPortal,
            menuItems.deleteProduct,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.PendingCustomer]: compact([
        menuItems.updateInformationRequest,
        menuItems.sendAdditionalInformationToMarket,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            !isEmpty(marketRequest.informationRequested) && menuItems.removeInformationRequested,
            !marketRequest.quoteId && menuItems.attachQuote,
            menuItems.addDeclinationReason,
            menuItems.deleteProduct,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.QuoteReady]: compact([
        hasFlowQuote && menuItems.viewQuote,
        menuItems.editQuote,
        hasFlowQuote && menuItems.shareQuote,
        hasFlowQuote && !marketRequest.quote?.sharingDetails?.quoteShared && menuItems.markQuoteAsShared,
        menuItems.rejectQuote,
        hasFlowQuote &&
          createNestedSection(
            messages.submissionWorkspace.marketRequestItem.dropDownItems.bindingAndPolicy,
            compact([menuItems.setAwaitingBinder, menuItems.attachBinder, menuItems.attachPolicy]),
          ),
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            menuItems.removeQuote,
            menuItems.addDeclinationReason,
            isEmpty(marketRequest.informationRequested)
              ? menuItems.addInformationRequest
              : menuItems.updateInformationRequest,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.AwaitingBinder]: compact([
        menuItems.viewQuote,
        menuItems.editQuote,
        menuItems.attachBinder,
        menuItems.attachPolicy,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            menuItems.removeQuote,
            menuItems.rejectQuote,
            isEmpty(marketRequest.informationRequested)
              ? menuItems.addInformationRequest
              : menuItems.updateInformationRequest,
            menuItems.addDeclinationReason,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.Bound]: compact([
        menuItems.viewQuote,
        menuItems.viewBinder,
        menuItems.editBinder,
        menuItems.shareBinder,
        !marketRequest.quote?.sharingDetails?.binderShared && menuItems.markBinderAsShared,
        menuItems.attachPolicy,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([menuItems.removeBinder]),
        ),
      ]),
      [SubmissionMarketRequestStatus.Declined]: compact([
        menuItems.editDeclinationReason,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([menuItems.removeDeclination, menuItems.deleteProduct]),
        ),
      ]),
      [SubmissionMarketRequestStatus.PolicyIssued]: compact([
        menuItems.viewQuote,
        menuItems.viewBinder,
        menuItems.viewPolicy,
        menuItems.editPolicy,
        menuItems.sharePolicy,
        !marketRequest.quote?.sharingDetails?.policyShared && menuItems.markPolicyAsShared,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([menuItems.removePolicy]),
        ),
      ]),
      [SubmissionMarketRequestStatus.QuoteNotAccepted]: compact([
        menuItems.editQuote,
        menuItems.viewQuote,
        createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            menuItems.removeQuote,
            !isEmpty(marketRequest.informationRequested) && menuItems.removeInformationRequested,
            menuItems.addDeclinationReason,
            isEmpty(marketRequest.informationRequested)
              ? menuItems.addInformationRequest
              : menuItems.updateInformationRequest,
          ]),
        ),
      ]),
      [SubmissionMarketRequestStatus.AwaitingPolicy]: compact([
        menuItems.viewQuote,
        !hasBinder && menuItems.editQuote,
        hasBinder && menuItems.viewBinder,
        hasBinder && menuItems.editBinder,
        menuItems.attachPolicy,
        // TODO - Talk to Sarit and decide if we need to add more actions here
        /*  createNestedSection(
          messages.submissionWorkspace.marketRequestItem.dropDownItems.more,
          compact([
            menuItems.removeQuote,
            menuItems.rejectQuote,
            isEmpty(marketRequest.informationRequested)
              ? menuItems.addInformationRequest
              : menuItems.updateInformationRequest,
            menuItems.addDeclinationReason,
          ]),
        ), */
      ]),
    };

    return compact(config[status] ?? []);
  };

  const actions = getStatusBasedActions(marketRequest.status);

  return compact([
    {
      key: messages.submissionWorkspace.marketRequestItem.dropDownItems.actionsSection,
      items: actions ?? [],
    },
  ]);
}
