import { useMemo } from 'react';
import { LoaderOverlay } from '@common-components';
import { useCurrentUser } from 'hooks';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import { canUserReplyToEmail } from 'broker/components/EmailEditor/utils/can-user-reply-to-email';
import { EmailItemType } from 'broker/components/Emails/types';
import { useGetEmail } from 'broker/components/Emails/useGetEmail';
import { activityTypeToEmailTemplateType } from 'broker/configuration-mappers/email-templates-activity-log-config';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import useGetEmailEditorProps from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailEditorInnerPage/useGetEmailEditorProps';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailReplyLocationState, ReplyMode } from './types';
import { useFollowupTemplate } from './useFollowupTemplate';
import useReplyEmail from './useReplyEmail';

interface ContentProps {
  locationState: EmailReplyLocationState;
}

export default function Content({ locationState }: ContentProps) {
  const { partialSubmission, markets: submissionMarkets, logEmailActivities } = useSubmissionsWorkspace();
  const { endUser } = useCurrentUser();
  const {
    postEmailsSent: postEmailSentFromPageModeSettings,
    preSelectedTemplateType,
    templateContextMetaData,
    isLoading: isLoadingEmailEditorProps,
  } = useGetEmailEditorProps({ mode: locationState.mode }, partialSubmission!);

  const replyMode = locationState?.initialReplyMode || ReplyMode.Duplicate;
  const emailId = locationState?.submissionEmailId;
  const emailType = locationState?.emailType || EmailItemType.SubmissionOutbound;
  const activityType = locationState?.activityType;

  const { attachments, unifiedEmail } = useGetEmail({
    emailId,
    type: emailType,
  });

  const { recipientGroup, ccTeammates, repliedThreadSubject, repliedThreadBody, title, subtitle, isLoading } =
    useReplyEmail({
      replyMode,
      unifiedEmail,
      submission: partialSubmission,
      preSelectedTemplateType,
    });

  const memoizedPreselectedFileIds = useMemo(() => attachments.items.map((file) => file.id), [attachments.items]);

  const postEmailsSent = async (postEmailInformation: PostEmailInformation) => {
    await logEmailActivities(
      activityType ? activityTypeToEmailTemplateType[activityType] : undefined,
      Object.keys(postEmailInformation.submissionMarketIdToEmailId).map((submissionMarketId) => ({
        submissionMarketId,
        submissionEmailId: postEmailInformation.submissionMarketIdToEmailId[submissionMarketId],
      })),
    );

    if (postEmailSentFromPageModeSettings) {
      await postEmailSentFromPageModeSettings(postEmailInformation);
    }
  };

  const getRecipientGroupsProp = () => (recipientGroup ? [recipientGroup] : []);

  const followupTemplate = useFollowupTemplate(replyMode, partialSubmission, submissionMarkets, unifiedEmail);

  // Line of coverage is required for the add markets modal to show the relevant markets. and
  // The recipient group is a required in order for the editor to know upon initializing 'recipientGroupToDraft' whether
  // to initialize the recipientGroup in detached mode or not.
  // The recipientGroup is compared to undefined because it might also be 'null' which is a valid value.
  if (!partialSubmission || isLoading || isLoadingEmailEditorProps) {
    return <LoaderOverlay />;
  }

  function getInReplyToMessageId() {
    if (
      endUser &&
      // When duplicating an email the intent is to create a new email thread
      replyMode !== ReplyMode.Duplicate &&
      canUserReplyToEmail(endUser.email, unifiedEmail?.senderEmail, unifiedEmail?.recipient)
    ) {
      return unifiedEmail?.externalId;
    }
    return undefined;
  }

  return (
    <SubmissionEmailEditor
      emailEditorProps={{
        preSelectedRecipients: getRecipientGroupsProp(),
        preSelectedFileIds: memoizedPreselectedFileIds,
        ccTeammatesInit: ccTeammates,
        postEmailsSent,
        isSingleRecipientMode: replyMode === ReplyMode.FollowUp,
        repliedThreadSubject,
        repliedThreadBody,
        preSelectedTemplateType: preSelectedTemplateType ?? followupTemplate,
        templateContextMetaData,
        inReplyToMessageId: getInReplyToMessageId(),
      }}
    >
      <InnerPageLayout
        className="cap-email-react-page"
        title={title || ''}
        subTitle={subtitle || ''}
        footer={(innerPageProps) => <EmailEditorFooter {...innerPageProps} />}
        sx={{ p: 0 }}
      >
        {() => <EmailEditor />}
      </InnerPageLayout>
    </SubmissionEmailEditor>
  );
}
