import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { Box, Dialog } from '@common-components';
import { AIFeature, EmailTemplateType, QuoteFileIdType, SubmissionMarketRequestStatus, SubmissionStatus } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { attachmentPointText } from 'utils';
import { DialogContent, DialogHeader } from 'components/Dialog';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import { convertSubmissionMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { useNavigateBack } from 'broker/hooks';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import SideBySide from 'broker/pages/SubmissionWorkspacePage/components/Layout/SideBySide/SideBySide';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import { QuoteStatusStep } from 'broker/pages/SubmissionWorkspacePage/dialogs/QuoteStatusModal/types';
import { QuoteStatusLocationState } from 'broker/pages/SubmissionWorkspacePage/dialogs/QuoteStatusModal/utils';
import { useStatusChangeDialog } from 'broker/pages/SubmissionWorkspacePage/dialogs/SubmissionStatusChangeDialog/useStatusChangeDialog';
import { useWorkspaceName } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import { useComparison } from './useComparison';
import useQuoteModalState from './useQuoteModalState';

interface ContentProps {
  locationState: QuoteStatusLocationState;
  aiFeature: AIFeature;
}

export default function Content({ locationState, aiFeature }: ContentProps) {
  const { markets, layers, submission, bindQuote, issueQuote, updateSubmissionMarketRequest } =
    useSubmissionsWorkspace();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showStatusChangeDialog } = useStatusChangeDialog();

  const navigateBack = useNavigateBack();
  const workspaceName = useWorkspaceName();
  const state = useQuoteModalState(locationState);
  const { selectedQuote, fileType, policyDetails, flowCommission } = state;

  const selectedQuoteFileIds =
    aiFeature === AIFeature.BinderPolicyCompare ? selectedQuote.binderFileIds : selectedQuote.marketQuoteFileIds;
  const { uploadedFileIds } = locationState;
  const { comparisonPopupTitle } = messages.submissionWorkspace.quoteComparison;
  const [activeStep, setActiveStep] = useState<QuoteStatusStep>(QuoteStatusStep.Comparison);

  const { showToast } = useToast();

  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  const [localFileIds, setLocalFileIds] = useState<string[]>([...uploadedFileIds, ...selectedQuoteFileIds]);

  useEffect(() => {
    setLocalFileIds(uniq([...uploadedFileIds, ...selectedQuoteFileIds, ...selectedFiles.map((file) => file.id)]));
  }, [uploadedFileIds, selectedFiles, selectedQuoteFileIds]);

  useComparison({ quoteId: selectedQuote.id, uploadedFileIds, aiFeature });

  const addStatus = async () => {
    setIsSubmitting(true);
    try {
      if (fileType === QuoteFileIdType.Binder) {
        await bindQuote(selectedQuote.id, uploadedFileIds, policyDetails, flowCommission);
        showToast('success', {
          message: messages.quoteStatusModal.bound.successMessage(selectedQuote.marketName!),
        });
        showStatusChangeDialog({
          current: submission!.status,
          newStatus: SubmissionStatus.Issuing,
        });
      } else if (fileType === QuoteFileIdType.Policy) {
        await issueQuote(selectedQuote.id, uploadedFileIds, policyDetails, flowCommission);
        showToast('success', {
          message: messages.quoteStatusModal.binderPolicyIssued.successMessage(selectedQuote.marketName!),
        });
        showStatusChangeDialog({
          current: submission!.status,
          newStatus: SubmissionStatus.Issued,
        });
      }
      navigateBack();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const selectedLayer = selectedQuote.layer;

  const layerTitle = attachmentPointText(selectedLayer?.attachmentPoint);

  const statusFileMismatch = () => {
    setActiveStep(QuoteStatusStep.DontMatch);
  };

  let modalMessages;

  if (fileType === QuoteFileIdType.Binder) {
    modalMessages = messages.quoteStatusModal.bound;
  } else if (aiFeature === AIFeature.BinderPolicyCompare) {
    modalMessages = messages.quoteStatusModal.binderPolicyIssued;
  } else {
    modalMessages = messages.quoteStatusModal.quotePolicyIssued;
  }

  if (!markets || !layers || !submission) {
    return null;
  }

  const marketRequests = submissionMarketsToExtendedMarketRequests(markets, submission!);

  const activeMarketRequest = marketRequests.find((marketRequest) => marketRequest.quoteId === selectedQuote.id);

  return activeStep === QuoteStatusStep.Comparison ? (
    <Dialog className="cap-quote-status-modal" fullScreen open={activeStep === QuoteStatusStep.Comparison}>
      <DialogHeader
        id="quote-status-modal-header"
        title={`${modalMessages.title(layerTitle)} - ${workspaceName}`}
        onClose={navigateBack}
      />
      <DialogContent disablePaddings>
        <SideBySide
          item1={{
            header: modalMessages.sideBySide.headerItem1(selectedQuote.marketName!),
            fileIds: selectedQuoteFileIds,
          }}
          item2={{
            header: modalMessages.sideBySide.headerItem2(selectedQuote.marketName!),
            fileIds: uploadedFileIds,
          }}
          cancelButton={{
            children: modalMessages.sideBySide.cancelButton,
            onClick: statusFileMismatch,
          }}
          proceedButton={{
            children: modalMessages.sideBySide.proceedButton,
            onClick: addStatus,
            loading: isSubmitting,
          }}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <SubmissionEmailEditor
      emailEditorProps={{
        preSelectedTemplateType:
          fileType === QuoteFileIdType.Binder ? EmailTemplateType.BinderCorrection : EmailTemplateType.PolicyCorrection,
        preSelectedFileIds: localFileIds,
        preSelectedRecipients: activeMarketRequest?.market?.contacts
          ? [convertSubmissionMarketToRecipientGroup(activeMarketRequest.market, activeMarketRequest.market.contacts)]
          : [],
        postEmailsSent: async () => {
          await updateSubmissionMarketRequest(activeMarketRequest?.id || '', {
            status:
              fileType === QuoteFileIdType.Binder
                ? SubmissionMarketRequestStatus.AwaitingBinder
                : SubmissionMarketRequestStatus.AwaitingPolicy,
          });
        },
      }}
    >
      <InnerPageLayout
        className="cap-quote-status-request"
        title={`${modalMessages.title(layerTitle)} - ${workspaceName}`}
        footer={(innerPageProps) => <EmailEditorFooter {...innerPageProps} cancelButtonText={messages.buttons.close} />}
      >
        {() => (
          <Box position="relative" height={1}>
            <AIButtonWithPopup popupTitle={comparisonPopupTitle} top={110} right={37} />
            <EmailEditor />
          </Box>
        )}
      </InnerPageLayout>
    </SubmissionEmailEditor>
  );
}
