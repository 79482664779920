import { QuoteStatus } from 'enums';
import { Quote } from 'types';

export function ignoreQuote(quote: Quote) {
  const statusConfig: { [status in QuoteStatus]: boolean } = {
    [QuoteStatus.Quoting]: false,
    [QuoteStatus.Selected]: false,
    [QuoteStatus.Rejected]: true,
    [QuoteStatus.Processing]: true,
  };

  return statusConfig[quote.status];
}

export function filterSingleInLayerQuotes(quotes: Quote[]) {
  // Filter out quotes that are not single in layer
  return quotes.filter((quote) => quote.isSingleInLayer);
}

export function filterBindRequestQuotes(quotes: Quote[]) {
  // Bind request is relevant only for quotes that are single but still in quoting state
  return quotes.filter((quote) => quote.isSingleInLayer).filter((quote) => quote.status === QuoteStatus.Quoting);
}

export function filterPolicyRequestQuotes(quotes: Quote[]) {
  return quotes.filter((quote) => quote.binderFileIds.length > 0);
}
