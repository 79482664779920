import { isEmpty } from 'lodash';
import { AIFeature, QuoteFileIdType } from 'enums';
import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import Content from './components/Content';
import { QuoteStatusLocationState } from './utils';

export default function QuoteStatusModal() {
  const getAIContextFeature = (state: QuoteStatusLocationState) => {
    if (state.fileType === QuoteFileIdType.Binder) {
      return AIFeature.QuoteBinderCompare;
    }
    if (!isEmpty(state.selectedQuote.binderFileIds)) {
      return AIFeature.BinderPolicyCompare;
    }
    return AIFeature.QuotePolicyCompare;
  };

  return (
    <WithRouteLocationState routeKey={RouteKey.QuoteStatus} isStateRequired>
      {(locationState) => {
        const aiFeature = getAIContextFeature(locationState);
        return (
          <AIContextProvider feature={aiFeature}>
            <Content locationState={locationState} aiFeature={aiFeature} />
          </AIContextProvider>
        );
      }}
    </WithRouteLocationState>
  );
}
