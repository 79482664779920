import { BoxItem } from 'box-ui-elements/es';
import { compact } from 'lodash';
import { QuoteStatus } from 'enums';
import { ExtendedMarketRequest, Quote } from 'types';

interface ActiveQuoteConfig {
  isActive: boolean;
}

export function getActiveQuotes(quotes: Quote[]): Quote[] {
  const activeQuoteConfig: Record<QuoteStatus, ActiveQuoteConfig> = {
    [QuoteStatus.Quoting]: { isActive: true },
    [QuoteStatus.Selected]: { isActive: true },
    [QuoteStatus.Rejected]: { isActive: false },
    [QuoteStatus.Processing]: { isActive: false },
  };

  return quotes.filter((quote) => activeQuoteConfig[quote.status].isActive);
}

export function getSubjectivitiesFromQuotes(marketRequests: ExtendedMarketRequest[], attachedFiles: BoxItem[]): string {
  const marketRequestsWithQuotes = marketRequests.filter((marketRequest) => marketRequest.quote);
  const subjectivitiesContent = compact(
    attachedFiles.map((attachedFile) => {
      const marketRequestFromAttachedFile = marketRequestsWithQuotes.find((marketRequest) =>
        marketRequest.quote?.capitolaQuoteFileIds.includes(attachedFile.id),
      );
      if (!marketRequestFromAttachedFile?.quote?.subjectivities) {
        return null;
      }
      return `${marketRequestFromAttachedFile?.market.marketName}:<br>
      ${marketRequestFromAttachedFile?.quote?.subjectivities}`;
    }),
  );
  if (subjectivitiesContent.length === 0) {
    return '';
  }
  return subjectivitiesContent.join('<br><br>');
}
