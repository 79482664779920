import { isNil } from 'lodash';
import { useState } from 'react';
import { PromptResultFeature, SubmissionEmailLabel, SubmissionStatus } from 'enums';
import { useBoolean, useGenerateAcknowledgmentContent, useMutatePromptResult, useSearchInsuranceProduct } from 'hooks';
import { messages } from 'i18n';
import theme from 'themes';
import { InsuranceProduct } from 'types';
import { logger } from 'utils';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import { createRecipientGroupFromSubmissionCustomer } from 'broker/components/Emails/recipient-utils';
import SubmissionEmailEditorWithAI from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditorWithAI';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import AcknowledgmentEmailInnerPageLayout from './AcknowledgmentEmailInnerPageLayout';
import { AcknowledgmentLocationState } from './types';

export interface AcknowledgmentEmailContentProps {
  locationState?: AcknowledgmentLocationState;
  onProductsSelected: (products: InsuranceProduct[]) => Promise<void>;
}

export default function AcknowledgmentEmailContent({
  locationState,
  onProductsSelected,
}: AcknowledgmentEmailContentProps) {
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const [isDirty, { set: setIsDirty }] = useBoolean(true);

  const { items: allInsuranceProducts, isLoading: isLoadingProducts } = useSearchInsuranceProduct();
  const selectedProductIds = locationState ? locationState.selectedProductIds : [];
  const { generateAcknowledgmentContent } = useGenerateAcknowledgmentContent();
  const [aiGeneratedBodyContent, setAiGeneratedBodyContent] = useState<string | undefined>(undefined);
  const { createPromptResult } = useMutatePromptResult();

  const isLoading = isLoadingProducts || !partialSubmission;

  const aiInputData = {
    submissionId: partialSubmission!.id,
    selectedProductIds,
  };

  const decorateGeneratedContent = (generatedContent: string) =>
    `<span style="font-size: 20px"><span style="color: ${theme.palette.purple[700]}">Thank you for your submission! </span>🎉</span><br><br>Hey @CUSTOMER-FIRST-NAMES,<br><br><div>${generatedContent}</div><br><br><div>@SIGNATURE</div>`;

  const { title } = messages.acknowledgmentFlow;

  const subject = `SUB REC'D | @INSURED-NAME - @COVERAGE-LINES`;

  return (
    <SubmissionEmailEditorWithAI
      isDirty={isDirty}
      contentDecorator={decorateGeneratedContent}
      initialBodyContent={partialSubmission!.draftAckEmailBody}
      isLoading={isLoading}
      title={title}
      aiThread={{
        createThread: () => generateAcknowledgmentContent(aiInputData),
        creationCondition: !!partialSubmission && isNil(partialSubmission.draftAckEmailBody),
        callback: (content) => {
          setAiGeneratedBodyContent(content);
        },
      }}
      emailEditorProps={(bodyContent) => ({
        preSelectedRecipients: [createRecipientGroupFromSubmissionCustomer(partialSubmission!)],
        emailLabel: SubmissionEmailLabel.Acknowledgment,
        repliedThreadBody: bodyContent,
        repliedThreadSubject: subject,
        postEmailsSent: async ({ draftPreview }: PostEmailInformation) => {
          try {
            // Clear the draft ack email body and selected products after sending the email
            await updateSubmission(partialSubmission!.id, {
              status: SubmissionStatus.New,
              draftAckEmailBody: null,
              draftAckSelectedProducts: null,
            });

            if (locationState) {
              const selectedProducts = allInsuranceProducts.filter((insuranceProduct) =>
                locationState.selectedProductIds.includes(insuranceProduct.id),
              );
              // Avoid success toast if no products are selected
              if (selectedProducts.length > 0) {
                await onProductsSelected(selectedProducts);
              }
            }

            if (aiGeneratedBodyContent !== undefined) {
              // run api in the background to create a prompt result (no need to wait for it)
              createPromptResult.mutateAsync({
                data: {
                  result: aiGeneratedBodyContent,
                  input: aiInputData,
                  modifiedResult: draftPreview.body,
                  feature: PromptResultFeature.AcknowledgmentEmail,
                  submissionId: partialSubmission!.id,
                },
              });
            }
          } catch (error) {
            logger.log('error', error as Error);
          }
        },
      })}
    >
      <AcknowledgmentEmailInnerPageLayout setIsDirty={setIsDirty} />
    </SubmissionEmailEditorWithAI>
  );
}
