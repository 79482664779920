import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import shortUUID from 'short-uuid';
import { MarketRequestType, QuoteFileIdType } from 'enums';
import { useSearchUserMarket, useToast } from 'hooks';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { InsuranceProduct } from 'types';
import {
  BROKER_NESTED_ROUTES,
  BrokerUrlParams,
  WORKSPACE_TABS_ROUTES,
  WorkspaceTabsRoutesValues,
} from 'broker/broker-routes';
import Navigate from 'broker/components/Navigate';
import { RouteKey } from 'broker/enums';
import { useLocation, useUpdateQueryParam } from 'broker/hooks';
import { ProductsWrapper } from 'broker/pages/SubmissionWorkspacePage/components/common/ProductsWrapper';
import AcknowledgmentEmail from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Acknowledgment';
import AddMarkets from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddMarkets';
import AddProducts from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts';
import ConceptualTower from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower';
import EditSubmissionNew from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew';
import EmailEditorInnerPage from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailEditorInnerPage';
import EmailReplyInnerPage from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage';
import ExpiringTower from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ExpiringTower';
import MarketCard from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketCard';
import MarketingReport from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport';
import MarketingUpdate from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingUpdate';
import MarketingUpdateEmail from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingUpdateEmail';
import ProposalFlowPage from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ProposalFlow/ProposalFlowPage';
import Quote from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote';
import QuoteComparisonInsights from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights';
import SelectProducts from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/SelectProducts/SelectProducts';
import SendQuoteStatusRequest from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/SendQuoteStatusRequest';
import { MultipleAIFeaturesContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/MultipleAIFeaturesContextProvider';
import { CompareQuotesSideBySideModal } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesSideBySide/CompareQuotesSideBySideModal';
import QuoteStatusModal from 'broker/pages/SubmissionWorkspacePage/dialogs/QuoteStatusModal/QuoteStatusModal';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { SelectedUserMarketsProps } from 'broker/pages/SubmissionWorkspacePage/types';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import Marketing from './Marketing';
import PreMarketing from './PreMarketing';

interface TabsPanelProps {
  defaultTab?: WorkspaceTabsRoutesValues;
}

export default function TabsPanel({ defaultTab = WORKSPACE_TABS_ROUTES.MARKETING }: TabsPanelProps) {
  const { submission, markets, addSubmissionMarkets, reFetchWorkspace } = useSubmissionsWorkspace();
  const marketsIdsInSubmission = markets.map((market) => market.userMarketId);
  const { items: userMarkets } = useSearchUserMarket();
  // if no submission in context it means it doesn't contain the required fields for the marketing
  const isSubmissionBlockForMarketing = !submission;

  const { showDelayedToast } = useToast();
  const location = useLocation();
  const updateQueryParam = useUpdateQueryParam();
  const { setSelectedProductsSubmitApi } = useUiStoreActions();

  const onActiveMarketChanged = () => {
    // Clear selected file and selected email since they might not belong to the selected market
    updateQueryParam({
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
  };

  const onMarketsSelected = async (addedMarkets: SelectedUserMarketsProps[]) => {
    const groupKey = shortUUID().new();
    await addSubmissionMarkets(
      addedMarkets.map((addedMarket) => ({
        userMarketId: addedMarket.id,
        marketRequestType: addedMarket.marketRequestType,
        contactIds: addedMarket.contacts.map((contact) => contact.id),
        activityLogGroupKey: groupKey,
      })),
      false,
    );

    await reFetchWorkspace();

    await showDelayedToast(
      'success',
      { message: messages.marketsModal.marketsAddedToast(addedMarkets.length) },
      dialogTransitionDurationValue,
    );
  };

  const onProductsSelected = async (products: InsuranceProduct[]) => {
    const groupKey = shortUUID().new();
    await addSubmissionMarkets(
      products.map((product) => {
        const userMarket = userMarkets.find((item) => item.marketId === product.marketId);
        if (!userMarket) {
          throw new Error(`User market not found for marketId: ${product.marketId}`);
        }
        return {
          userMarketId: userMarket.id,
          contactIds: product.contactIds,
          marketRequestType: product.isPrimary ? MarketRequestType.Primary : MarketRequestType.Excess,
          activityLogGroupKey: groupKey,
          insuranceProductId: product.id,
        };
      }),
      false,
    );

    setSelectedProductsSubmitApi(products.filter((product) => product.externalProductId));

    await reFetchWorkspace();

    await showDelayedToast(
      'success',
      { message: messages.addProductsPage.productsAddedToast(products.length) },
      dialogTransitionDurationValue,
    );
  };

  return (
    <MultipleAIFeaturesContextProvider>
      <Routes>
        {/* Sub Routes */}
        {Object.values(WORKSPACE_TABS_ROUTES)
          .filter(
            (workspaceTab) => !isSubmissionBlockForMarketing || workspaceTab === WORKSPACE_TABS_ROUTES.PRE_MARKETING,
          )
          .map((workspaceTab) => (
            <Fragment key={workspaceTab}>
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.SINGLE_MARKET}/*`}
                element={<MarketCard onMarketChanged={onActiveMarketChanged} />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.CONCEPTUAL_TOWER}/*`}
                element={<ConceptualTower />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.PROPOSAL}/*`}
                element={<ProposalFlowPage />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.EDIT_SUBMISSION}/*`}
                element={<EditSubmissionNew />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.ACKNOWLEDGMENT_EMAIL}/*`}
                element={<AcknowledgmentEmail onProductsSelected={onProductsSelected} />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT}/*`}
                element={<MarketingReport />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_UPDATE}/*`}
                element={<MarketingUpdate />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_UPDATE_EMAIL}/*`}
                element={<MarketingUpdateEmail />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.EXPIRING_TOWER}/*`}
                element={<ExpiringTower />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_STATUS_REQUEST}/*`}
                element={<SendQuoteStatusRequest />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.ADD_PRODUCTS}/*`}
                element={
                  <ProductsWrapper>
                    {(props) => <AddProducts {...props} onProductsSelected={onProductsSelected} />}
                  </ProductsWrapper>
                }
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.SELECT_PRODUCTS}/*`}
                element={<ProductsWrapper>{(props) => <SelectProducts {...props} />}</ProductsWrapper>}
              />

              {submission && (
                <Route
                  path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.ADD_MARKETS}/*`}
                  element={
                    <AddMarkets
                      submitToMarkets
                      preferredCoverageLines={submission.coverageLines}
                      marketsIdsInSubmission={marketsIdsInSubmission}
                      onMarketsSelected={onMarketsSelected}
                      disableSelection
                    />
                  }
                />
              )}
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/*`}
                element={<Quote fileType={QuoteFileIdType.MarketQuote} />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.BINDER}/*`}
                element={<Quote fileType={QuoteFileIdType.Binder} />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.POLICY}/*`}
                element={<Quote fileType={QuoteFileIdType.Policy} />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR}/*`}
                element={<EmailEditorInnerPage />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_REPLY}/*`}
                element={<EmailReplyInnerPage />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_STATUS}/*`}
                element={<QuoteStatusModal />}
              />

              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_COMPARISON_INSIGHTS}/*`}
                element={<QuoteComparisonInsights />}
              />
              <Route
                path={`/${workspaceTab}/${BROKER_NESTED_ROUTES.WORKSPACE.COMPARE_QUOTES_SIDE_BY_SIDE}/*`}
                element={<CompareQuotesSideBySideModal />}
              />
            </Fragment>
          ))}
        {/* Tab Routes */}
        {!isSubmissionBlockForMarketing && (
          <Route path={`/${WORKSPACE_TABS_ROUTES.MARKETING}/*`} element={<Marketing />} />
        )}

        <Route path={`/${WORKSPACE_TABS_ROUTES.PRE_MARKETING}/*`} element={<PreMarketing />} />
        <Route
          path="*"
          element={
            <Navigate
              isStep={false}
              to={{ pathname: `./${defaultTab}`, search: location.search }}
              navigateOptions={{ routeKey: RouteKey.WorkspaceTab, state: undefined, replace: true }}
            />
          }
        />
      </Routes>
    </MultipleAIFeaturesContextProvider>
  );
}
