import { MarketRequestType } from 'enums';
import { UserMarket } from 'types';
import {
  ACKNOWLEDGMENT_EMAIL,
  ADD_MARKETS,
  ADD_PRODUCTS,
  BINDER,
  CONCEPTUAL_TOWER,
  EDIT_SUBMISSION,
  EMAIL_EDITOR,
  EMAIL_REPLY,
  EXPIRING_TOWER,
  MARKETING,
  MARKETING_REPORT,
  MARKETING_UPDATE_EMAIL,
  POLICY,
  PRE_MARKETING,
  PROPOSAL,
  QUOTE,
  QUOTE_STATUS_REQUEST,
  SINGLE_MARKET,
} from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';

export interface SelectedUserMarketsProps extends Pick<UserMarket, 'id' | 'contacts'> {
  marketRequestType?: MarketRequestType;
}

export const BASE_TOOLBOX_TABS = {
  TASKS: 'Tasks',
  FILES: 'Files',
  EMAIL: 'Email',
  TOWER: 'Tower',
  ACTIVITY_LOG: 'ActivityLog',
} as const;

export const ADDITIONAL_TOOLBOX_TABS = {
  CONTACTS: 'Contacts',
  MARKET_INFO: 'MarketInfo',
} as const;

export const TOOLBOX_TABS = {
  ...BASE_TOOLBOX_TABS,
  ...ADDITIONAL_TOOLBOX_TABS,
} as const;

export const WORKSPACE_PAGES_ROUTES = {
  PRE_MARKETING,
  EDIT_SUBMISSION,
  ACKNOWLEDGMENT_EMAIL,
  MARKETING,
  SINGLE_MARKET,
  MARKETING_REPORT,
  QUOTE,
  PROPOSAL,
  BINDER,
  POLICY,
  CONCEPTUAL_TOWER,
  EXPIRING_TOWER,
  ADD_MARKETS,
  ADD_PRODUCTS,
  EMAIL_EDITOR,
  QUOTE_STATUS_REQUEST,
  EMAIL_REPLY,
  MARKETING_UPDATE_EMAIL,
} as const;

export const WORKSPACE_OVERLAY_ROUTES = {
  INFORMATION_REQUEST: RouteKey.InformationRequest,
  DECLINATION: RouteKey.Declination,
} as const;

export type WorkspaceToolboxTabsRoutesValues =
  | (typeof WORKSPACE_PAGES_ROUTES)[keyof typeof WORKSPACE_PAGES_ROUTES]
  | (typeof WORKSPACE_OVERLAY_ROUTES)[keyof typeof WORKSPACE_OVERLAY_ROUTES];

type BaseToolboxTabsKeys = keyof typeof BASE_TOOLBOX_TABS;
export type BaseToolboxTabs = (typeof BASE_TOOLBOX_TABS)[BaseToolboxTabsKeys];

type AdditionalToolboxTabsKeys = keyof typeof ADDITIONAL_TOOLBOX_TABS;
export type AdditionalToolboxTabs = (typeof ADDITIONAL_TOOLBOX_TABS)[AdditionalToolboxTabsKeys];

export type ToolboxTabs = BaseToolboxTabs | AdditionalToolboxTabs;
